import { useState, useCallback } from 'react';
import styles from './App.module.sass'
import ContactsSection from './components/ContactsSection';
import Header from './components/Header';
import Main from './components/Main';
import Modal from './components/Modal';
import Room from './components/Room';
import Rooms from './components/Rooms';
import Status from './components/Status';
import Thank from './components/Thank';
import { enableBodyScroll } from 'body-scroll-lock';
import Infra from './components/Infra';

const App = () => {
  const [modal, setModal] = useState(false)
  const [thank, setThank] = useState(false)

  const closeModal = useCallback(() => {
    setModal(false)
    enableBodyScroll(document)
  }, [])

  return (
    <div className={styles.root}>
      <Header />
      <Main setThank={setThank} />
      <Status setModal={setModal} />
      <Infra />
      <Rooms setModal={setModal} />
      <Room />
      <ContactsSection setThank={setThank} />
      {modal && <Modal modal={modal} closeModal={closeModal} setThank={setThank} />}
      {thank && <Thank setThank={setThank} />}
    </div>
  );
}

export default App;
