import React from 'react'
import styles from './Main.module.sass'
import mainImage from '../../images/main.jpg'
import logo from '../../images/logo.svg'
import Form from '../Form'

const Main = ({ setThank }) => {
  return (
    <section style={{ backgroundImage: `url(${mainImage})` }} className={styles.root}>
      <div className="container">
        <div className={styles.inner}>
          <div className={styles.content}>
            <img src={logo} alt="logo" className={styles.logo} />
            <h1 className={styles.title}>
              Добавьте бриллиант
              в&nbsp;вашу коллекцию
              недвижимости
            </h1>
            <p className={styles.text}>
              Отельные номера 5*&nbsp;для инвестиций в&nbsp;Сочи
            </p>
          </div>
          <Form setThank={setThank} color='#000' btnText='Получить предложение' />
        </div>
      </div>
    </section>
  )
}

export default Main
