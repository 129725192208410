import React from 'react'
import { Link, animateScroll as scroll } from "react-scroll";
import styles from './Header.module.sass'
import clsx from 'clsx'

const links = [
  {
    name: 'Локация',
    scrollTo: 'location'
  },
  {
    name: 'Инфраструктура',
    scrollTo: 'infrastructure'
  },
  {
    name: 'Контакты',
    scrollTo: 'contacts'
  },

]

const Menu = ({ activeMenu, setActiveMenu }) => {

  const handleClickItem = () => {
    const documentWidth = document.documentElement.offsetWidth
    if (documentWidth < 768) {
      setActiveMenu(false)
    }
  }

  const menuItems = links.map((item, i) => {
    return (
      <li key={i} className={styles.menu_item}>
        <Link
          onClick={handleClickItem}
          className={styles.menu_link}
          activeClass="active"
          to={item.scrollTo}
          spy={true}
          smooth={true}
          offset={0}
          duration={1000}
        >
          {item.name}
        </Link>
      </li>
    )
  })

  return (
    <nav className={clsx(styles.menu, activeMenu && styles.menu_active, '')}>
      <ul className={styles.menu_list}>
        {menuItems}
      </ul>
    </nav>
  )
}

export default Menu
