import React from 'react'
import styles from './Contacts.module.sass'

const Contacts = () => {
  return (
    <div className={styles.root}>
      <a href="tel:88002220003">8 800 222 00 03</a>
      <a href="https://yandex.ru/maps/-/CCUruWWIXB" target='_blank' rel="noreferrer">Сочи, ул. Виноградная, 14</a>
    </div>
  )
}

export default Contacts
