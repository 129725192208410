import React, { useState } from 'react'
import styles from './Form.module.sass'
import InputName from './InputName'
import Button from '../Button'
import InputPhone from './InputPhone'
import Loader from '../Loader/Loader'
import { disableBodyScroll } from 'body-scroll-lock';

const Form = (props) => {
  const { color, btnText, setThank, closeModal } = props
  const [valueInputName, setValueInputName] = useState('')
  const [valueInputPhone, setValueInputPhone] = useState('')
  const [loader, setLoader] = useState(false)
  const [errorInputName, setErrorInputName] = useState(false)
  const [errorInputPhone, setErrorInputPhone] = useState(false)

  const onSubmit = async (e) => {
    e.preventDefault()

    if(valueInputName.length < 2) {
      setErrorInputName(true)

      return
    } else if(valueInputPhone.length < 6) {
      setErrorInputPhone(true)
      
      return
    }

    setErrorInputPhone(false)
    setErrorInputName(false)

    const data = {
      name: valueInputName,
      phone: valueInputPhone
    }

    const formData = new FormData();

    for (let key in data) {
      formData.append(`${key}`, data[key])
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };

    try {
      setLoader(true)
      await fetch('https://radisson.argroup.su/submit.php', requestOptions)
      setLoader(false)
      if (props?.closeModal) {
        closeModal()
      }
      setThank(true)
      disableBodyScroll(document)
    } catch (error) {
      console.log(error)
    }
  }

  const inputStyles = {
    color,  
    borderColor: color
  }
  return (
    <form onSubmit={onSubmit} className={styles.root}>
      <div className={styles.inputs}>
        <InputName error={errorInputName} style={inputStyles} valueInputName={valueInputName} setValueInputName={setValueInputName} />
        <InputPhone error={errorInputPhone} valueInputPhone={valueInputPhone} style={inputStyles} setValueInputPhone={setValueInputPhone} />
      </div>
      {loader ? <Loader /> : <Button type='submit'>{btnText}</Button>}

    </form>
  )
}

export default Form
