import React from 'react'
import clsx from 'clsx'
import './burger.sass'

const Burger = ({ setActiveMenu, activeMenu }) => {

  const handleClickBurger = () => {
    if (activeMenu) {
      setActiveMenu(false)
    } else {
      setActiveMenu(true)
    }
  }

  return (
    <div onClick={handleClickBurger} className={clsx('burger', activeMenu && 'active', '')}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default Burger
