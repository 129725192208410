import React from 'react'
import styles from './Form.module.sass'

const InputName = ({ style, setValueInputName, valueInputName, error }) => {

  const errorStyles = {
    borderColor: '#B2271C',
    color: style.color
  }

  const onChange = (e) => {
    const validateText = e.target.value.replace(/[^a-zA-Zа-яА-Я]+/gi, '')
    setValueInputName(validateText)
  }

  return (
    <input onChange={onChange} style={error ? errorStyles : style } placeholder='Имя' value={valueInputName} className={styles.input} type="text" />
  )
}

export default InputName
