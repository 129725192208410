import React, { useState } from 'react'
import Burger from '../Burger'
import styles from './Header.module.sass'
import Menu from './Menu'

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(false)

  return (
    <header className={styles.root}>
      <div className="container">
        <div className={styles.inner}>
          <Burger activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
          <Menu setActiveMenu={setActiveMenu} activeMenu={activeMenu} />
          <div className={styles.contacts}>
            <a href="tel:88007000663">8 800 700-06-63</a>
            <a href="https://yandex.ru/maps/-/CCUruWWIXB" target='_blank' rel="noreferrer">г. Сочи, ул. Виноградная, 14</a>
          </div>
        </div>
      </div>
    </header>
  )
}


export default Header
