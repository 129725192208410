import React from 'react'
import styles from './Button.module.sass'
import { disableBodyScroll } from 'body-scroll-lock';

const Button = React.memo(({ children, type, setModal, style }) => {
  const handleButtonClick = () => {
    if (type === 'button') {
      setModal(true)
      disableBodyScroll(document)
    }
  }

  return (
    <button style={{ ...style }} onClick={handleButtonClick} type={type} className={styles.root}>
      {children}
    </button>
  )
})

export default Button
