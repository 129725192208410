import React from 'react'
import styles from './Room.module.sass'
import roomImage from '../../images/room.jpg'

const Room = () => {
  return (
    <div className={styles.root}>
      <img src={roomImage} alt="room" />
    </div>
  )
}

export default Room
