import React from 'react'
import closeIcon from '../../images/close.png'
import styles from './CloseModal.module.sass'

const CloseModal = React.memo(({ closeModal }) => {

  const onClick = () => {
    closeModal()
  }

  return (
    <img onClick={onClick} className={styles.root} src={closeIcon} alt="close modal" />
  )
})

export default CloseModal
