import React from 'react'
import styles from './Infra.module.sass'
import bgImage from '../../images/infra.png'
import bottomCenterImage from '../../images/infra/bottom.jpg'

const list = ['Ужинайте всей семьей в ресторане Stoke', 'Расслабляйтесь в роскошном SPA', 'Оставайтесь в тонусе благодаря фитнес-центру', 'Проводите время у бассейна в любую погоду']

const Infra = () => {
  return (
    <section style={{ backgroundImage: `url(${bgImage})` }}  className={styles.root} id='infrastructure'>
      <div className={styles.top}>
        <div className="container">
          <div className={styles.inner}>
            <h2 className={styles.title}>
              Премиальная инфраструктура
            </h2>
            <ul className={styles.list}>
              {list.map((item, i) => {
                return <li key={i} className={styles.list_item}>{item}</li>
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
          <img src={bottomCenterImage} alt="" />
      </div>

    </section>
  )
}

export default Infra
