import React from 'react'
import InputMask from 'react-input-mask';
import styles from './Form.module.sass'

const InputPhone = ({ style, setValueInputPhone, valueInputPhone, error }) => {

  const errorStyles = {
    borderColor: '#B2271C',
    color: style.color
  }

  const onChange = (e) => {
    setValueInputPhone(e.target.value)
  }

  return (
    <InputMask mask="+7 (999) 999 99-99" value={valueInputPhone} onChange={onChange}>
      {(inputProps) => <input className={styles.input} type="text" required style={error ? errorStyles : style} {...inputProps} placeholder='8 800 000 00 00' />}
    </InputMask>
  )
}

export default InputPhone
