import React, { useCallback } from 'react'
import CloseModal from '../CloseModal'
import styles from './Thank.module.sass'
import logo from '../../images/logo.svg'
import { enableBodyScroll } from 'body-scroll-lock';
import presentation from '../../images/Radisson_Collection_Sochi.pdf'


const Thank = ({ setThank }) => {

  const closeModal = useCallback(() => {
    setThank(false)
    enableBodyScroll(document)
  }, [])

  return (
    <>
      <div onClick={closeModal} className={styles.thank_close}></div>
      <div className={styles.root}>
        <CloseModal closeModal={closeModal} />
        <img className={styles.logo} src={logo} alt="logo" />
        <h2 className={styles.title}>
          УСПЕШНО!
        </h2>
        <p className={styles.text}>
          В&nbsp;ближайшее время мы&nbsp;свяжемся с&nbsp;вами
        </p>
        <a href={presentation} target='_blank' className={styles.link} rel="noreferrer">Скачать буклет</a>
      </div>
    </>
  )
}

export default Thank
