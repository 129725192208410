import React from 'react'
import Contacts from '../Contacts'
import Form from '../Form'
import styles from './ContactsSection.module.sass'
import contactsImage from '../../images/contacts.jpg'

const ContactsSection = ({ setThank }) => {
  return (
    <section style={{ backgroundImage: `url(${contactsImage})` }} id='contacts' className={`${styles.root}`}>
      <div className="container">
        <div className={styles.inner}>
          <h2 className={styles.title}>
            Контакты
          </h2>
          <div className={styles.contacts}>
            <Contacts />
          </div>
          <p className={styles.text}>
            Посещение офиса продаж только
            по&nbsp;предварительной записи
          </p>
          <p className={styles.text}>
            ПН-ПТ 8:00–18:00<br />СБ 10:00–15:00<br />ВС по договоренности
          </p>
          <Form setThank={setThank} color='#fff' btnText='Скачать буклет' />
        </div>
      </div>
    </section>
  )
}

export default ContactsSection
