import React from 'react'
import styles from './Rooms.module.sass'
import room1 from '../../images/rooms/1.jpg'
import room2 from '../../images/rooms/2.jpg'
import room3 from '../../images/rooms/3.jpg'
import Button from '../Button'

const roomsImages = [room1, room2, room3]

const Rooms = ({ setModal }) => {
  return (
    <section className={styles.root} id='rooms'>
      <div className="container">
        <div className={styles.inner}>
          <h3 className={styles.title}>
            Каждый номер&nbsp;&mdash; неповторимый <br /> экспонат вашей коллекции
          </h3>
          <p className={styles.text}>
            Просторные номера от&nbsp;известного бюро Spectrum. <br />
            Комфорт и&nbsp;роскошь в&nbsp;каждой детали. Премиальные
            материалы&nbsp;&mdash; дерево, мрамор, латунь.
          </p>
          <ul className={styles.list}>
            {roomsImages.map((image, i) => {
              return (
                <li key={i} className={styles.list_item}>
                  <img src={image} alt="room" />
                </li>
              )
            })}
          </ul>
          <Button style={{ color: '#fff', border: '1px solid #fff', backgroundColor: 'transparent' }} setModal={setModal} type='button'>Скачать буклет</Button>
        </div>
      </div>
    </section>
  )
}

export default Rooms
