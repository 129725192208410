import React from 'react';
import cn from './Loader.module.sass'

const Loader = () => {
  return (
    <div className={cn.container}>
      <span className={cn.circle}></span>
      <span className={cn.circle}></span>
      <span className={cn.circle}></span>
      <span className={cn.circle}></span>
    </div>
  );
}

export default Loader;
