import React from 'react'
import styles from './Modal.module.sass'
import Form from '../Form'
import CloseModal from '../CloseModal';

const Modal = ({ setThank, closeModal }) => {



  return (
    <>
      <div onClick={closeModal} className={styles.modal_close}></div>
      <div className={styles.root}>
        <CloseModal closeModal={closeModal} />
        <h2 className={styles.title}>
          Оставьте заявку и&nbsp;получите буклет
        </h2>
        <Form setThank={setThank} closeModal={closeModal} color='#000' btnText='Скачать буклет' />
      </div>
    </>
  )
}

export default Modal
