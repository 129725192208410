import React from 'react'
import Button from '../Button'
import styles from './Status.module.sass'
import bgImage from '../../images/status.png'
import logo from '../../images/logo_2.svg'


const list = ['Исключительная локация', 'Дизайн от архитекторов с мировым именем', 'Всего 105 номеров', '300 метров от резиденции президента и храма Владимира', 'Сервис от 5-звездочного оператора']

const Status = ({ setModal }) => {
  return (
    <section id='location' style={{ backgroundImage: `url(${bgImage})` }} className={`${styles.root} status`}>
      <div className="container">
        <div className={styles.inner}>
          <div className={styles.content}>
            <img src={logo} alt="logo" className={styles.img} />
            <h2 className={styles.title}>
              Статус владельца
              в&nbsp;Radisson Collection
              hotel sochi
            </h2>
            <p className={styles.text}>
              Radisson Collection Hotel Sochi&nbsp;&mdash; это ограниченное предложение премиального бренда.
            </p>
            <ul className={styles.list}>
              {list.map((item, i) => {
                return <li key={i} className={styles.list_item}>{item}</li>
              })}
            </ul>
            <Button setModal={setModal} type='button' style={{ color: '#000', border: '1px solid #000', backgroundColor: 'transparent' }}>Скачать буклет</Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Status
